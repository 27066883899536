import './App.css';

function App() {
  return (
    <main className="content">
      <h1>Dogged Software</h1>
    </main>
  );
}

export default App;
